/** @flow
 * StratoDem Analytics : multifamily
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Link as SmoothScrollLink } from "react-scroll/modules";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';
import RotatingText from '../../components/RotatingText';
import { FAQ, FAQElement } from '../../components/FAQ';

import { openHubSpotForm } from '../../core/utils';

import ForecastingAccuracy from "../../images/ForecastingAccuracy.gif";
import PortfolioWideAnalysis from "../../images/PortfolioWideAnalysis.gif";
import HouseholdNetWorthSegmentationHeatmap from "../../images/HouseholdNetWorthSegmentationHeatmap.gif";
import CompareFutureDemandGrowth from "../../images/CompareFutureDemandGrowth.gif";
import IdentifyTopGrowthMarkets from "../../images/IdentifyTopGrowthMarkets.gif";
import DiveIntoTopMarketAreas from "../../images/DiveIntoTopMarketAreas.gif";

import '../index.scss';
import './senior-housing.scss';


type Props = {
  data: Object,
};

export default class Index extends React.Component {
  props: Props;

  render() {
    const { data } = this.props;

    return (
      <Layout containerClassName="solutions">
        <SEO
          title="Senior housing research suite"
          keywords={['stratodem', 'analytics', 'real estate', 'commercial', 'demographic', 'economic', 'forecasting', 'senior living', 'senior housing', 'seniors housing', 'market research']}
          description="Hyper-local economic and geo-demographic research suite for senior housing investors and developers"
        />
        <div className="sd-section solutions-hero">
          <h3>Senior housing investors and operators trust us to</h3>
          <p>
            <RotatingText
              rotateText={[
                'measure portfolio|performance.',
                'forecast seniors|net worth.',
                'analyze acquisition|targets.',
                'build analytics|pipelines.',
                'provide demographics|data.',
              ]}
              period={2000}
            />
          </p>
          <button
            className="btn-tertiary main"
            onClick={openHubSpotForm}
          >
            Request a demo
          </button>

          <h4>Quick navigation</h4>
          <SmoothScrollLink
            to="why-stratodem"
            spy
            smooth
            duration={500}
            offset={-60}
          >
            <button className="btn-secondary">
              Why StratoDem
            </button>
          </SmoothScrollLink>
          <SmoothScrollLink
            to="what-you-get"
            spy
            smooth
            duration={500}
            offset={-60}
          >
            <button className="btn-secondary">
              What you get
            </button>
          </SmoothScrollLink>
          <SmoothScrollLink
            to="frequently-asked-questions"
            spy
            smooth
            duration={500}
            offset={-60}
          >
            <button className="btn-secondary">
              FAQ
            </button>
          </SmoothScrollLink>
        </div>

        <div id="why-stratodem" className="sd-section why-stratodem">
          <div className="section-header">
            <h3>Why StratoDem Analytics</h3>
          </div>

          <Row>
            <Col md={7}>
              <h3>Segementation granularity</h3>
              <div className="product-screenshot">
                <Img fluid={data.seniorHousingBostonMapImage.childImageSharp.fluid} />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Examine hyper-localized markets by income, net worth (up to $2.5 million or higher),
                for age 75-79, 80-84, 85+ or any other age cohort, which <strong>no
                legacy-generation data
                provider can generate accurately at our level of granularity.</strong>
              </p>
              <p>
                This granularity and accuracy offers significant analysis advantages for assisted
                living, where the <strong>average age of entry is in the eighties</strong>, and
                high-end senior
                living, which requires <strong>higher levels of net worth than tracked by
                legacy-generation
                data providers.</strong>
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>Nowcasting vital signs</h3>
              <div className="product-screenshot">
                <Img fluid={data.nowcastingImage.childImageSharp.fluid} />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Get the most accurate and up-to-date economic and demographic forecasts using the
                StratoDem Analytics engine nowcasts.
              </p>
              <p>
                When the current generation of data sources provide 2018 demographic estimates, they
                rely on Census Bureau data that was actually collected on average four years ago. At
                StratoDem Analytics, data science enables accurate nowcast data as of last month.
                That typically provides clients <strong>one to four years head start in
                understanding demand
                shifts in the markets.</strong>
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>Forecasting accuracy</h3>
              <div className="custom-gif">
                <img
                  style={{display: 'inline-block'}}
                  src={ForecastingAccuracy}
                  alt="Segmentation heatmap by age and household income"
                />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Model back-testing shows that StratoDem Analytics’ forecasts are up to 35% more
                accurate than legacy-generation data estimates on household wealth, and up to 10x
                more accurate at forecasting demographic changes.
              </p>
              <p>
                The StratoDem Analytics engine not only ingests more than 200 data sets from dozens
                of governmental sources, but it is also the nation’s first and only system that
                connects all that data to fuel advanced real estate analysis. As a result, only
                StratoDem Analytics can run massively multivariate analyses, Bayesian hierarchical
                modeling and other machine-learning techniques to understand where the underlying
                state of real estate demand factors is today and where it is most likely to shift
                over the next five years.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>Portfolio-wide analysis</h3>
              <div className="custom-gif">
                <img
                  style={{display: 'inline-block'}}
                  src={PortfolioWideAnalysis}
                  alt="Portfolio-wide analysis using Blaise ML by StratoDem Analytics"
                />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Meet Blaise ML by StratoDem Analytics, our <strong>machine learning
                analyst.</strong> On large
                portfolios with hundreds of properties, Blaise ML provides clients desktop access to
                answers for questions about senior living property investment, development and
                disposition, <strong>predicting which targets will have the strongest or weakest
                demand-growth outlook.</strong>
              </p>
              <p>
                Answer questions such as:
              </p>
              <ul>
                <li>Which properties will see the strongest growth of income-qualified senior
                  households
                </li>
                <li>Which properties will see declining growth rates?</li>
                <li>Where is demand-curve growth most likely to outstrip supply-curve growth?</li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>Deep dives into market areas</h3>
              <div className="custom-gif">
                <img
                  style={{display: 'inline-block'}}
                  src={HouseholdNetWorthSegmentationHeatmap}
                  alt="Household net worth segmentation heatmap"
                />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Identify what makes submarkets unique. For individual or multiple market areas,
                Blaise ML by StratoDem Analytics answers questions such as "Which net worth segments of
                my catchment area are likely to grow faster than the broad metro area?", giving you
                shareable written analysis, maps, charts and downloadable versions of the underlying
                data.
              </p>
              <p>
                Use suggested questions to ask Blaise ML about markets and customize across segments
                and time periods, or create new questions. Blaise ML deploys machine learning and
                natural language generation to build the answers to questions, leaving StratoDem
                Analytics clients more time to focus on optimizing deals and strategy.
              </p>
            </Col>
          </Row>
        </div>

        <div className="sd-section">
          <Row>
            <Col md={6}>
              <h3>Ready to get in touch?</h3>
            </Col>
            <Col md={6}>
              <button
                className="btn-tertiary"
                style={{marginTop: 28}}
                onClick={openHubSpotForm}>
                Request a demonstration
              </button>
            </Col>
          </Row>
        </div>

        <div id="what-you-get" className="sd-section">
          <div className="section-header">
            <h3>One platform for senior housing portfolio analysis</h3>
          </div>

          <Row>
            <Col md={7}>
              <h3>1. Compare future demand growth across properties</h3>
              <div className="custom-gif">
                <img
                  style={{display: 'inline-block'}}
                  src={CompareFutureDemandGrowth}
                  alt="Compare future demand growth across properties using Blaise ML by StratoDem Analytics"
                />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Upload a portfolio of property locations and compare historical, current and
                forecast market conditions.
              </p>
              <p>
                <strong>For example: </strong>After uploading 700+ senior living properties,
                Blaise ML helps to frame the questions that matter by geographic region or property
                set - dramatically speeding up the research and due diligence process.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>2. Identify top growth markets</h3>
              <div className="custom-gif">
                <img
                  style={{display: 'inline-block'}}
                  src={IdentifyTopGrowthMarkets}
                  alt="Identify top growth markets across a portfolio using Blaise ML by StratoDem Analytics"
                />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Get a deeper analysis of a selected set of top- or bottom-performing properties,
                powered by Blaise ML and the StratoDem Analytics Engine.
              </p>
              <p>
                <strong>For example: </strong>Blaise ML analyzes and ranks your portfolio based on
                key trends such as:
              </p>
              <ul>
                <li>Divergence of growth rates for household income or net worth, picking out
                  properties with the strongest market dynamics
                </li>
                <li>Shifts in growth, up or down, helping clients to track which markets are
                  likeliest to be undervalued or overvalued
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>3. Dive into top market areas</h3>
              <div className="custom-gif">
                <img
                  style={{display: 'inline-block'}}
                  src={DiveIntoTopMarketAreas}
                  alt="Dive deep into market segmentation for top and bottom properties"
                />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Drill down into the top- or bottom- performing properties you pull with Blaise ML.
              </p>
              <p>
                <strong>For example: </strong>After Blaise ML identifies a top-ranking property
                in <Link to="/products/stratodem-research-suite#portfolio">Portfolio</Link>, it
                can direct you to the <Link to="/products/stratodem-research-suite#markets">
                Markets
              </Link> application, where you can break down the market by granular submarket or
                segments by age, household income, net worth, home value, educational attainment,
                etc. Blaise ML uses maps, charts, heatmaps and written analyses to transform
                raw data from the StratoDem Analytics Engine into easy-to-share content (and data
                exports) for segments such as households 75 to 84 years old with household income
                between
                $25,000 and $75,000.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <h3>4. Create investment portfolio dashboards</h3>
              <div className="product-screenshot">
                <Img fluid={data.createInvestmentPortfolioImage.childImageSharp.fluid} />
              </div>
            </Col>
            <Col md={5}>
              <p>
                Upload your investment portfolio and create reports. <strong>Download the data in
                Excel format</strong> and use it in presentations or other internal Excel
                applications.
              </p>
              <p>
                <strong>For example: </strong>Compare your investment portfolio market performance
                with:
              </p>
              <ul>
                <li>The US as a whole</li>
                <li>Senior living REIT portfolios</li>
                <li>Senior living owner / operator portfolios</li>
                <li>Any of the other real estate portfolios that StratoDem Analytics now tracks</li>
              </ul>
            </Col>
          </Row>
        </div>

        <div className="sd-section">
          <Row>
            <Col md={6}>
              <h3>Let's get started</h3>
            </Col>
            <Col md={6}>
              <button
                className="btn-tertiary"
                style={{marginTop: 28}}
                onClick={openHubSpotForm}>
                Request a demonstration
              </button>
            </Col>
          </Row>
        </div>

        <div className="sd-section" id="frequently-asked-questions">
          <Row>
            <div className="section-header">
              <h3>Frequently asked questions</h3>
            </div>
            <FAQ>
              <FAQElement
                initialOpen={false}
                label="What core services does StratoDem Analytics provide?"
              >
                <div>
                  <p>
                    StratoDem Research Suite provides organization-wide access to a powerful data
                    science
                    platform that allows clients to analyze historical trends, track current
                    developments
                    in our nowcasts, and build confidence in future market performance with
                    forecasts
                    of
                    economics and geo-demographics for any market or submarket across the US.
                  </p>
                  <p>
                    We offer access to an ecosystem of data science products including:
                  </p>
                  <ul>
                    <li>
                      Portfolio analysis tools to power national- and portfolio-level analysis for
                      questions such as <em>Which market areas will see the strongest growth of
                      income-qualified senior households over the next three years?</em></li>
                  </ul>
                </div>
              </FAQElement>
              <FAQElement
                initialOpen={false}
                label="How do I use the StratoDem Analytics platform?"
              >
                <div>
                  <p>
                    StratoDem Analytics provides <Link to="/products/stratodem-research-suite">StratoDem
                    Research Suite</Link>, an online analytics
                    solution that provides market- and submarket-level analysis for economics
                    and geo-demographics. StratoDem Research Suite includes:
                  </p>
                  <ul>
                    <li><strong>Markets</strong>. Select and analyze any market with mapping,
                      machine-written analysis, and economic and demographic nowcasts.
                    </li>
                    <li><strong>Portfolio</strong>. Build and track your portfolio from a
                      collection of markets. Identify top growth market areas across the US
                      based on target segment concentration, future growth patterns, or
                      economic risk factors.
                    </li>
                    <li><strong>API</strong>. Integrate StratoDem Analytics data from anywhere.
                      Create portfolio analysis templates for distribution across teams, in any
                      language including Excel VBA, Python, and R.
                    </li>
                  </ul>
                  <p>Included with access to each of our platforms are the following services:</p>
                  <ul>
                    <li><strong>Data download</strong>. Download data on a single market
                      or a portfolio of markets (bulk download) in a single Excel file.
                    </li>
                    <li><strong>Data upload</strong>. Work with us on the type of data you would
                      like to integrate (supply-size metrics, for example) and we will analyze and
                      map competitor locations against demand-side factors.
                    </li>
                    <li><strong>Onboarding for your whole team</strong>. Our team will walk through
                      example use cases during an initial onboarding period for all users. We also
                      open up access to our knowledge base for tutorials and case studies.
                    </li>
                    <li><strong>Technical support </strong>throughout your subscription period.</li>
                  </ul>
                </div>
              </FAQElement>
              <FAQElement
                initialOpen={false}
                label="Which geographic coverage can I get?"
              >
                <div>
                  <p>
                    StratoDem Analytics provides complete coverage for the entire US (except
                    some remote regions of Alaska), including:
                  </p>
                  <ul>
                    <li>400+ metros and metropolitan divisions</li>
                    <li>3,000+ counties</li>
                    <li>30,000+ ZIP codes</li>
                    <li>70,000+ census tracts; for example, the Boston MSA has over 900 census
                      tracts tracked by the StratoDem Analytics Engine
                    </li>
                  </ul>
                </div>
              </FAQElement>
              <FAQElement
                initialOpen={false}
                label="Which metrics does StratoDem Analytics forecast?"
              >
                <div>
                  <p>
                    StratoDem Analytics provides nowcasts and forecasts for critical economic
                    and demographic metrics, including:
                  </p>
                  <ul>
                    <li>Local market economic growth (think GDP for counties and metros)</li>
                    <li>Alpha and beta, our proprietary estimates breaking down the sustainability
                      and volatility of growth patterns for markets and industry segments
                    </li>
                    <li>Population by age, race or ethnicity, and educational attainment level</li>
                    <li>Household income by age, broken down by source of income, such as:
                      <ul>
                        <li>Wage and salary income</li>
                        <li>Income from interest, dividends and rental income</li>
                        <li>Income from retirement savings, including pensions and annuities</li>
                        <li>Social security</li>
                      </ul>
                    </li>
                    <li>Household net worth by age</li>
                    <li>Home values by age and/or by household income</li>
                    <li>Homeownership rates by age</li>
                    <li>Housing costs for homeowners and renters</li>
                    <li>Insurance coverage by age of householder</li>
                  </ul>
                  <p>This is just a starting point for what StratoDem Analytics provides for
                    clients, and we also frequently add, build, or integrate new data sets for
                    clients during the initial on-boarding process or as more comprehensive
                    semi-custom builds.
                  </p>
                  <p>
                    Don't see something you need on the list above?
                  </p>
                  <p>We may already have it, but we also frequently add, build, or integrate new
                    data sets for clients during the initial onboarding process.
                  </p>
                </div>
              </FAQElement>
              <FAQElement
                initialOpen={false}
                label="What is nowcasting?"
              >
                <div>
                  <p>
                    Nowcasting is the prediction of the present state of data before it is released
                    by the Federal governmental statistical agencies.
                  </p>
                  <p>
                    <strong>Example: </strong>StratoDem Analytics can predict 2017 regional GDP
                    numbers (Gross
                    Metropolitan Product or GMP) in January 2018, when the Bureau of Economic
                    Analysis will not release the 2017 data until November 2018. The Goldman Sachs
                    Growth Tracker and the Federal Reserve Bank of Atlanta GDPNow deploy similar
                    methods for national growth estimates, although only StratoDem Analytics can
                    release this data at local-market level instead of national level.
                  </p>
                  <p><strong>Why does it matter?</strong></p>
                  <p>
                    Every data set is released on a lag. <strong>For example: </strong>The 2017
                    American Community
                    Survey population data is released by the US Census Bureau at the end of 2018.
                    2016 county-level income data is released by the US Bureau of Labor Statistics
                    at the end of 2017. Household net worth data is released by the Federal Reserve
                    Bank once every three years. Nowcasting creates accurate estimates of where that
                    data will be before it is released, giving clients dramatic leads in
                    understanding local market conditions before competitors &ndash; <strong>providing
                    true
                    competitive advantage.</strong>
                  </p>
                </div>
              </FAQElement>
              <FAQElement
                initialOpen={false}
                label="What is Bayesian statistics?"
              >
                <div>
                  <p>
                    Bayesian modeling is an approach to statistics that uses the mathematics of
                    probability to combine data with prior information.
                  </p>
                  <p>
                    <strong>Why use Bayesian modeling? </strong>Bayesian models drive inferences
                    which are more precise than would be obtained by either of those sources of
                    information alone. StratoDem Analytics uses Bayesian modeling to combine
                    hundreds of data sets at varying frequencies and geographic levels in a
                    statistically rigorous, robust way.
                  </p>
                  <p>
                    As one example, the StratoDem Analytics engine builds its core population
                    forecasts using hierarchical Bayesian time-series modeling with data from
                    national, state, metro, county, census tract data (and more!).
                  </p>
                  <p>
                    The downside of Bayesian modeling? The models typically come at extremely high
                    computational cost, which is why complex implementations are only now becoming
                    possible in this era of scalable data science.
                  </p>
                </div>
              </FAQElement>
              <FAQElement
                initialOpen={false}
                label="Can StratoDem Analytics develop additional analyses or applications that meet my needs?"
              >
                <div>
                  <p>
                    <strong>Yes. </strong>StratoDem Analytics frequently builds what we call
                    semi-custom deliveries, where we do some of the following for our clients:
                  </p>
                  <ul>
                    <li>
                      Build custom dashboards to track an existing portfolio of properties for
                      investors or developers
                    </li>
                    <li>
                      Create new data and forecasts for topics ranging from insurance coverage to
                      household income broken down by source of income
                    </li>
                    <li>
                      Rebuild already existing analysis pipelines and technological stacks of
                      advisory services firms to replace them with faster deliveries, better design,
                      and more accurate data
                    </li>
                  </ul>
                </div>
              </FAQElement>
            </FAQ>
          </Row>
        </div>
      </Layout>);
  }
}

export const pageQuery = graphql`
    query {
      nowcastingImage: file(relativePath: { eq: "Nowcasting.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      seniorHousingBostonMapImage: file(relativePath: { eq: "SeniorHousingBostonMap.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      createInvestmentPortfolioImage: file(relativePath: { eq: "CreateInvestmentPortfolioReports.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`;
