/** @flow
 * StratoDem Analytics : index
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import FAQ from './FAQ';
import FAQElement from './FAQElement';


export {
  FAQ,
  FAQElement,
};
