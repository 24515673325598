/** @flow
 * StratoDem Analytics : RotatingText
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';

type State = {
  renderedText: string,
};

type Props = {
  rotateText: Array<string>,
  period: number,
}

export default class RotatingText extends React.Component {
  state: State;
  props: Props;

  constructor(props) {
    super(props);

    this.state = {renderedText: ''};
  }

  componentDidMount() {
    const TxtRotate = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    };

    TxtRotate.prototype.tick = function () {
      const i = this.loopNum % this.toRotate.length;
      let fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      fullTxt = window.screen.width <= 675 ? fullTxt.replace('|', '<br />') : fullTxt.replace('|', ' ');
      const txtToRender = window.screen.width <= 675 ? this.txt.replace('|', '<br />') : this.txt.replace('|', ' ');

      this.el.innerHTML = '<span class="wrap">' + txtToRender + '</span>';

      const that = this;
      let delta = 110 - Math.random() * 50;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && txtToRender === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && txtToRender === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    const elements = document.getElementsByClassName('txt-rotate');
    new TxtRotate(elements[0], this.props.rotateText, this.props.period);
  }

  render() {
    return <span className="txt-rotate">{this.props.rotateText[0]}</span>
  }
}
